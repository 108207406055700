import Actions from '../Actions';

const cms = (state = {}, action) => {
  Actions.forEach(actionName => {
    if (actionName === action.type) {
      const newState = state;
      newState[actionName] = action.payload;
      return newState;
    }
  });
  return state;
};

export default cms;
