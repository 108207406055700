class Cms {
  constructor() {}

  /**
   * Load single external script
   * 
   * @param {string} src - Script src
   * @param {object} options - Optional settings
   * @param {boolean} [options.async=false] - Script element async attribute
   * @param {boolean} [options.defer=false] - Script element defer attribute
   * @param {string} [options.parentEl=head] - Script parent element selector (head or body)
   * 
   * @returns {Promise}
   */
  loadScript(src, options = {}) {
    
    const settings = Object.assign({
      async: false,
      defer: false,
      parentEl: 'head',
      resolveWith: null,
    }, options);

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = settings.async;
      script.defer = settings.defer;
      script.onload = () => {
        resolve(settings.resolveWith || undefined);
      };
      script.onerror = () => {
        reject('Cms.loadScript(): Could not load script');
      }
      document.getElementsByTagName(settings.parentEl)[0].appendChild(script);
    });

  }

}

export default Cms;
