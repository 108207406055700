import Cms from './Cms';

class Menu extends Cms {
  
  constructor(mainMenuEl, options = {}) {
    super();

    this.settings = Object.assign({
      hitArea: true,
      hitAreaZIndex: 99,
      hitAreaMatchMedia: '(min-width: 992px)',
      orientationDiff: 60,
      debug: false,
      debugColor: 'rgba(255,72,0,.25)',
    }, options);
    
    this.keys = {
      UP: ['ArrowUp'],
      DOWN: ['ArrowDown'],
      LEFT: ['ArrowLeft'],
      RIGHT: ['ArrowRight'],
      TAB: ['Tab'],
      ESC: ['Escape'],
      SPACE: [' ', 'Spacebar'],
      ENTER: ['Enter'],
    };

    this.initializedHitArea = false;

    this.mainMenuEl = (typeof mainMenuEl === 'string') ? document.querySelector(mainMenuEl) : mainMenuEl;

    this.menuEl = null;
    this.menuRole = null;
    this.menuItems = null;

    this.boundFocus = e => this.onFocus(e);
    this.boundKeyDown = e => this.onKeyDown(e);
    this.boundOutsideClick = e => this.onOutsideClick(e);
    this.boundMenuItemClick = e => this.onMenuItemClick(e);
    if (this.settings.hitArea) {
      this.boundMouseEnter = e => this.onMouseEnter(e);
      this.boundMouseLeave = e => this.onMouseLeave(e);
    }

    // hit area and optional matchMedia
    this.hitAreaMatchMedia(() => {
      // matched media
      // console.log('matched media');
      if (!this.initializedHitArea) {
        this.initHitArea();
      }
    }, () => {
      // no media match
      // console.log('no media match');
      if (this.initializedHitArea) {
        this.destroyHitArea();
      }
    }, () => {
      // ignore media matching
      // console.log('ignore media matching');
      if (!this.initializedHitArea) {
        this.initHitArea();
      }
    });

    // Array.from(this.mainMenuEl.querySelectorAll('[role="menuitem"]')).forEach(linkEl => {
    //   linkEl.addEventListener('focus', this.boundFocus, false);
    //   linkEl.addEventListener('keydown', this.boundKeyDown, false);
    //   document.addEventListener('click', this.boundOutsideClick, false);
    //   if (this.settings.hitArea) {
    //     linkEl.closest('[role="none"]').addEventListener('mouseenter', this.boundMouseEnter, false);
    //     linkEl.closest('[role="none"]').addEventListener('mouseleave', this.boundMouseLeave, false);
    //   }
    // });
    
    this.init();
  }

  /**
   * Check for media query monitoring
   * 
   * @param {function} cbMatch 
   * @param {function} cbNoMatch 
   * @param {function} cbIgnore 
   * 
   * @returns {void}
   */
  hitAreaMatchMedia(cbMatch, cbNoMatch, cbIgnore) {
    if (this.settings.hitAreaMatchMedia) {
      const mm = window.matchMedia(this.settings.hitAreaMatchMedia);
      if (mm.matches) {
        cbMatch.call(null);
      } else {
        cbNoMatch.call(null);
      }
      mm.onchange = (e) => {
        if (e.matches) {
          cbMatch.call(null);
        } else {
          cbNoMatch.call(null);
        }
      }
    } else {
      // don't check media
      cbIgnore.call(null);
    }
  }

  /**
   * Add dynamic SVG hit area to dropdown/dropout menus
   * 
   * @param {HTMLElement} linkEl - Menu link HTML element
   * 
   * @returns {void}
   */
  addHitArea(linkEl) {
    const submenuEl = linkEl.closest('[role="none"]').querySelector('[role="menu"]');

    const linkElRect = linkEl.getBoundingClientRect();
    const submenuElRect = submenuEl.getBoundingClientRect();
    
    const { menuOrientation, viewBox, left, top } = this.getHitAreaData(linkElRect, submenuElRect);
    let path = null;
    const svgns = 'http://www.w3.org/2000/svg';
    const svgEl = document.createElementNS(svgns, 'svg');
    svgEl.setAttributeNS(null, 'viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}`);
    svgEl.setAttributeNS(null, 'width', viewBox.width);
    svgEl.setAttributeNS(null, 'height', viewBox.height);
    svgEl.style.position = 'absolute';
    svgEl.style.zIndex = this.settings.hitAreaZIndex;
    svgEl.style.pointerEvents = 'none';
    svgEl.style.left = `${left}px`;
    svgEl.style.top = `${top}px`;

    // mask:
    const maskId = `mask${new Date().getTime()}`;
    const maskEl = document.createElementNS(svgns, 'mask');
    maskEl.setAttributeNS(null, 'id', maskId);
    // mask rect:
    const rectEl = document.createElementNS(svgns, 'rect');
    rectEl.setAttributeNS(null, 'width', linkElRect.width);
    rectEl.setAttributeNS(null, 'height', linkElRect.height);
    rectEl.setAttributeNS(null, 'x', Math.abs(left));
    rectEl.setAttributeNS(null, 'y', Math.abs(top));
    rectEl.setAttributeNS(null, 'fill', '#000000');
    // mask bg:
    const maskBgEl = document.createElementNS(svgns, 'rect');
    maskBgEl.setAttributeNS(null, 'x', 0);
    maskBgEl.setAttributeNS(null, 'y', 0);
    maskBgEl.setAttributeNS(null, 'width', viewBox.width);
    maskBgEl.setAttributeNS(null, 'height', viewBox.height);
    maskBgEl.setAttributeNS(null, 'fill', '#ffffff');
    
    maskEl.appendChild(maskBgEl);
    maskEl.appendChild(rectEl);
    
    svgEl.appendChild(maskEl);

    if (menuOrientation === 'horizontal') {
      path = `
      M0 ${linkElRect.height + Math.abs(top)}
      Q ${viewBox.width} ${linkElRect.height + Math.abs(top)} ${viewBox.width} ${viewBox.height}
      V 0
      Q ${viewBox.width} ${Math.abs(top)} 0 ${Math.abs(top)}
      Z
      `;
    } else if (menuOrientation === 'vertical') {
      path = `
      M${Math.abs(left)} 0
      Q ${Math.abs(left)} ${viewBox.height} 0 ${viewBox.height}
      H ${viewBox.width}
      Q ${linkElRect.width + Math.abs(left)} ${linkElRect.height + Math.abs(top)} ${linkElRect.width + Math.abs(left)} 0
      Z
      `;
    }

    if (path) {
      const pathEl = document.createElementNS(svgns, 'path');
      pathEl.setAttributeNS(null, 'd', path);
      pathEl.setAttributeNS(null, 'mask', `url(#${maskId})`);
      pathEl.style.pointerEvents = 'auto';
      if (this.settings.debug) {
        pathEl.setAttributeNS(null, 'fill', this.settings.debugColor);
      } else {
        pathEl.setAttributeNS(null, 'fill', 'white');
        pathEl.setAttributeNS(null, 'fill-opacity', 0);
      }
      svgEl.appendChild(pathEl);
      linkEl.appendChild(svgEl);
    }
  }

  /**
   * Collect size, position data to hit area
   * 
   * @param {DOMRect} linkRect - Link element bounding client rect
   * @param {DOMRect} menuRect - Submenu element bounding client rect
   * 
   * @returns {object}
   */
  getHitAreaData(linkRect, menuRect) {
    const result = {
      viewBox: {
        x: 0,
        y: 0,
      },
    };

    // menu orientation
    const hDiff = Math.abs(linkRect.x - menuRect.x);
    if (hDiff >= this.settings.orientationDiff) {
      result.menuOrientation = 'horizontal';
    } else {
      result.menuOrientation = 'vertical';
    }

    if (result.menuOrientation === 'horizontal') {
      result.viewBox.width = hDiff;
      result.viewBox.height = (menuRect.height > linkRect.height) ? menuRect.height : linkRect.height;
      result.top = (menuRect.y > linkRect.y) ? 0 : -1 * Math.abs(linkRect.y - menuRect.y);
      result.left = 0;
    } else if (result.menuOrientation === 'vertical') {
      result.viewBox.width = (menuRect.width > linkRect.width) ? menuRect.width : linkRect.width;
      result.viewBox.height = Math.abs(linkRect.top - menuRect.top);
      result.top = 0;
      result.left = -1 * hDiff;
    }

    return result;
  }

  /**
   * Remove dynamic SVG hit area from link
   * 
   * @param {HTMLElement} linkEl - Menu link HTML element
   * 
   * @returns {void}
   */
  removeHitArea(linkEl) {
    const hitArea = linkEl.querySelector('svg');
    if (hitArea) {
      hitArea.parentNode.removeChild(hitArea);
    }
  }

  /**
   * Menu outside click event listener
   * 
   * @param {MouseEvent} e - Javascript MouseEvent object
   * 
   * @returns {void}
   */
  onOutsideClick(e) {
    let targetEl = e.target;
    do {
      if (targetEl == this.mainMenuEl) {
        return;
      }
      targetEl = targetEl.parentNode;
    } while(targetEl);
    this.cleanup();
  }

  /**
   * Menu item mouseEnter event listener
   * 
   * @param {MouseEvent} e - Javascript MouseEvent object
   * 
   * @returns {void}
   */
  onMouseEnter(e) {
    const linkEl = e.target.querySelector('[role="menuitem"]');
    const hasPopup = linkEl.hasAttribute('aria-haspopup') && linkEl.getAttribute('aria-haspopup') === 'true';
    if (hasPopup) {
      linkEl.setAttribute('aria-expanded', 'true');
      this.addHitArea(linkEl);
    }
  }

  /**
   * Menu item mouseLeave event listener
   * 
   * @param {MouseEvent} e - Javascript MouseEvent object
   * 
   * @returns {void}
   */
  onMouseLeave(e) {
    const linkEl = e.target.querySelector('[role="menuitem"]');
    const hasPopup = linkEl.hasAttribute('aria-haspopup') && linkEl.getAttribute('aria-haspopup') === 'true';
    if (hasPopup) {
      linkEl.setAttribute('aria-expanded', 'false');
      this.removeHitArea(linkEl);
    }
  }
  /**
   * Menu item click event listener
   * 
   * @param {MouseEvent} e - Javascript MouseEvent object
   * 
   * @returns {void}
   */
  onMenuItemClick(e) {
    const linkEl = (e.target.hasAttribute('role') && e.target.getAttribute('role') === 'menuitem') ? e.target : e.target.closest('[role="menuitem"]');
    if (linkEl && linkEl.hasAttribute('aria-haspopup') && linkEl.getAttribute('aria-haspopup') === 'true') {
      const subMenuEl = linkEl.closest('[role="none"]').querySelector('[role="menu"]');
      if (subMenuEl) {
        e.preventDefault();
        if (linkEl.hasAttribute('aria-expanded') && linkEl.getAttribute('aria-expanded') === 'false') {
          // open submenu:
          this.jumpIn(linkEl, subMenuEl);
        } else {
          // close submenu:
          linkEl.setAttribute('aria-expanded', 'false');
          linkEl.focus();
        }
      }
    }
  }

  /**
   * Menu link focus event listener
   * 
   * @param {KeyboardEvent} e - Javascript KeyboardEvent object
   * 
   * @returns {void}
   */
  onFocus(e) {
    // set current menu
    this.menuEl = e.target.closest('[role="menu"]') || e.target.closest('[role="menubar"]');
    this.menuRole = this.menuEl.getAttribute('role');
    this.menuItems = this.menuEl.querySelectorAll(':scope > [role="none"] [role="menuitem"]');
  }
  
  /**
   * Menu link keyDown event listener
   * 
   * @param {KeyboardEvent} e - Javascript KeyboardEvent object
   * 
   * @returns {void}
   */
  onKeyDown(e) {
    let action;
    let subMenuEl = null;
    const hasPopup = e.target.hasAttribute('aria-haspopup') && e.target.getAttribute('aria-haspopup') === 'true';
    if (hasPopup) {
      subMenuEl = e.target.closest('[role="none"]').querySelector('[role="menu"]');
    }

    if (this.isKey(e.key, 'ENTER') || this.isKey(e.key, 'SPACE')) {
      if (hasPopup && subMenuEl) {
        e.preventDefault();
        this.jumpIn(e.target, subMenuEl);
      }
    }
    if (this.isKey(e.key, 'TAB')) {
      this.cleanup();
    }
    if (this.isKey(e.key, 'ESC')) {
      this.jumpIn(e.target, this.mainMenuEl);
      this.cleanup();
    }
    // prevent page scroll
    if (this.isKey(e.key, 'UP') || this.isKey(e.key, 'DOWN')) {
      e.preventDefault();
    }

    if (this.menuRole === 'menubar') {
      if (this.isKey(e.key, 'RIGHT')) {
        action = 'next';
      } else if (this.isKey(e.key, 'LEFT')) {
        action = 'prev';
      } else if (this.isKey(e.key, 'DOWN')) {
        if (hasPopup && subMenuEl) {
          this.jumpIn(e.target, subMenuEl);
        }
      }
    } else if (this.menuRole === 'menu') {
      if (this.isKey(e.key, 'DOWN')) {
        action = 'next';
      } else if (this.isKey(e.key, 'UP')) {
        action = 'prev';
      } else if (this.isKey(e.key, 'LEFT')) {
        const parentLinkEl = (this.menuEl.closest('[role="none"]')) ? this.menuEl.closest('[role="none"]').querySelector(':scope > [role="menuitem"]') : null;
        if (parentLinkEl) {
          parentLinkEl.focus();
          if (parentLinkEl.hasAttribute('aria-expanded')) {
            parentLinkEl.setAttribute('aria-expanded', 'false');
          }
        }
      } else if (this.isKey(e.key, 'RIGHT')) {
        if (hasPopup && subMenuEl) {
          this.jumpIn(e.target, subMenuEl);
        }
      }
    }
    this.jump(e.target, action);
  }

  /**
   * Jump keyboard focus in specific direction
   * 
   * @param {HTMLElement} linkEl - Menu link HTML element
   * @param {string} direction - Jump direction (next | prev)
   * @param {object} options - Optional settings
   * @param {boolean} [options.loop=true] - Loop stepping? (eg. if direction is "next" and last item reached, auto jump back to first item?)
   * 
   * @returns {void}
   */
  jump(linkEl, direction, options = {}) {
    
    const settings = Object.assign({
      loop: true,
    }, options);

    const parentEl = linkEl.closest('[role="none"]');
    let nextLinkEl;
    let prevLinkEl;

    if (direction === 'next') {
      nextLinkEl = this.getNext(parentEl);
      if (nextLinkEl) {
        // next
        nextLinkEl.focus();
      } else {
        // first, if needed
        if (settings.loop) {
          this.menuItems[0].focus();
        }
      }
    }
    if (direction === 'prev') {
      prevLinkEl = this.getPrev(parentEl);
      if (prevLinkEl) {
        // prev
        prevLinkEl.focus();
      } else {
        // last, if needed
        if (settings.loop) {
          this.menuItems[this.menuItems.length - 1].focus();
        }
      }
    }
  }

  /**
   * Get next available interactive menu item element inside a specific menu / submenu
   * 
   * @param {HTMLElement} el - Menu link element parent ([role="none"]) element
   * 
   * @returns {null|HTMLElement} - Next available [role="menuitem"] element or null
   */
  getNext(el) {
    const nextEl = el.nextElementSibling;
    if (!nextEl) {
      return null;
    }
    const nextLinkEl = nextEl.querySelector(':scope > [role="menuitem"]');
    if (nextLinkEl) {
      return nextLinkEl;
    }
    return this.getNext(nextEl);
  }

  /**
   * Get previous available interactive menu item element inside a specific menu / submenu
   * 
   * @param {HTMLElement} el - Menu link element parent ([role="none"]) element
   * 
   * @returns {null|HTMLElement} - Previous available [role="menuitem"] element or null
   */
  getPrev(el) {
    const prevEl = el.previousElementSibling;
    if (!prevEl) {
      return null;
    }
    const prevLinkEl = prevEl.querySelector(':scope > [role="menuitem"]');
    if (prevLinkEl) {
      return prevLinkEl;
    }
    return this.getPrev(prevEl);
  }

  /**
   * Jump keyboard focus into specific submenu (on the first submenu item)
   * 
   * @param {HTMLElement} linkEl - Menu link HTML element
   * @param {HTMLElement} subMenuEl - Menu links submenu HTML element
   * 
   * @returns {void}
   */
  jumpIn(linkEl, subMenuEl) {
    if (linkEl.hasAttribute('aria-expanded')) {
      linkEl.setAttribute('aria-expanded', 'true');
    }
    subMenuEl.querySelector(':scope > [role="none"] [role="menuitem"]').focus();
  }

  /**
   * Reset all aria-expanded attribute values to false in this menu instance
   * 
   * @returns {void}
   */
  cleanup() {
    Array.from(this.mainMenuEl.querySelectorAll('[role="menuitem"]')).forEach(linkEl => {
      if (linkEl.hasAttribute('aria-expanded')) {
        linkEl.setAttribute('aria-expanded', 'false');
      }
    });
  }

  /**
   * Set all event listeners for this menu instance
   * 
   * @returns {void}
   */
  init() {
    Array.from(this.mainMenuEl.querySelectorAll('[role="menuitem"]')).forEach(linkEl => {
      linkEl.addEventListener('focus', this.boundFocus, false);
      linkEl.addEventListener('keydown', this.boundKeyDown, false);
      linkEl.addEventListener('click', this.boundMenuItemClick, false);
    });
    document.addEventListener('click', this.boundOutsideClick, false);
  }

  /**
   * Remove all event listeners from this menu instance
   * 
   * @returns {void}
   */
  destroy() {
    Array.from(this.mainMenuEl.querySelectorAll('[role="menuitem"]')).forEach(linkEl => {
      linkEl.removeEventListener('focus', this.boundFocus);
      linkEl.removeEventListener('keydown', this.boundKeyDown);
      linkEl.removeEventListener('click', this.boundMenuItemClick);
    });
    document.removeEventListener('click', this.boundOutsideClick);
  }

  initHitArea() {
    Array.from(this.mainMenuEl.querySelectorAll('[role="menuitem"]')).forEach(linkEl => {
      if (this.settings.hitArea) {
        linkEl.closest('[role="none"]').addEventListener('mouseenter', this.boundMouseEnter, false);
        linkEl.closest('[role="none"]').addEventListener('mouseleave', this.boundMouseLeave, false);
      }
    });
    this.initializedHitArea = true;
  }

  destroyHitArea() {
    Array.from(this.mainMenuEl.querySelectorAll('[role="menuitem"]')).forEach(linkEl => {
      if (this.settings.hitArea) {
        linkEl.closest('[role="none"]').removeEventListener('mouseenter', this.boundMouseEnter);
        linkEl.closest('[role="none"]').removeEventListener('mouseleave', this.boundMouseLeave);
      }
    });
    this.initializedHitArea = false;
  }

  /**
   * Check if pressed key is a specific key by name
   * 
   * @param {string} pressedKey - Pressed key name
   * @param {string} targetKey - Unified keyboard key name (keys object in constructor)
   * 
   * @returns {boolean}
   */
  isKey(pressedKey, targetKey) {
    return (this.keys[targetKey].indexOf(pressedKey) > -1) ? true : false;
  }
  
}

export default Menu;
