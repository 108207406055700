import { combineReducers } from 'redux';
import cms from './cms';
import lastAction from './lastAction';

const rootReducers = combineReducers({
  cms,
  lastAction,
});

export default rootReducers;
