export function responsiveTable(options = {}) {
  const settings = Object.assign({
    selector: 'table',
    wrapperClassname: 'table-responsive',
    wrapperTagname: 'div',
  }, options);

  Array.from(document.querySelectorAll(settings.selector)).forEach(tableEl => {
    if (tableEl.parentNode.classList.contains(settings.wrapperClassname)) {
      return;
    }
    const wrapperEl = document.createElement(settings.wrapperTagname);
    wrapperEl.classList.add(settings.wrapperClassname);
    wrap(tableEl, wrapperEl);
  });
}

export function responsiveEmbed(options = {}) {
  const settings = Object.assign({
    selector: 'iframe[src*="youtube"]',
    parent: 'body',
    defaultAspect: '4:3',
    exclude: 'ratio',
  }, settings);

  const wrapperStyles = {
    width: '100%',
    position: 'relative',
    padding: '0',
  };

  const embedStyles = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
  };

  const parentEl = (typeof settings.parent === 'string') ? document.querySelector(settings.parent) : parent;
  
  Array.from(parentEl.querySelectorAll(settings.selector)).forEach(el => {
    if (el.parentNode.classList.contains(settings.exclude)) {
      return;
    }
    let wrapper;
    let needsWrap = true;
    if (el.parentNode.tagName.toLowerCase() === 'p') {
      wrapper = el.parentNode;
      needsWrap = false;
    }
    const defaultAspect = settings.defaultAspect.split(':');
    const width = (el.hasAttribute('width') && el.getAttribute('width').match(/^\d+$/i)) ? parseInt(el.getAttribute('width')) : parseInt(defaultAspect[0]);
    const height = (el.hasAttribute('height') && el.getAttribute('height').match(/^\d+$/i)) ? parseInt(el.getAttribute('height')) : parseInt(defaultAspect[1]);
    const aspect = height / width;
    for (let [key, value] of Object.entries(embedStyles)) {
      el.style[key] = value;
    }
    if (!wrapper) {
      wrapper = document.createElement('div');
    }
    for (let [key, value] of Object.entries(wrapperStyles)) {
      wrapper.style[key] = value;
    }
    wrapper.style.paddingTop = `${aspect * 100}%`;
    if (needsWrap) {
      wrap(el, wrapper);
    }
  });
}

/**
 * Wrap an HTML element inside anoter HTML element
 * 
 * @param {HTMLElement} el - Element to wrap
 * @param {HTMLElement} wrapper - Elment to wrap with
 * 
 * @returns {void}
 */
function wrap(el, wrapper) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}
