import axios from 'axios';
import Cookies from 'js-cookie';
import DataStore from './DataStore';
import Menu from './Menu';
// import Tracker from './Tracker'; // jelenleg nincs rá szükség az egyszerűsített gtag és fbq consent beállítások miatt
import { responsiveTable, responsiveEmbed } from './utils';

export {
  axios,
  Cookies,
  DataStore,
  Menu,
  responsiveEmbed,
  responsiveTable,
  // Tracker,
};
