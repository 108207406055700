import { createStore } from 'redux';
import rootReducers from './reducers';
import Listeners from './Listeners';

// const store = createStore(testReducer);
const store = createStore(rootReducers);

const unsubscribe = store.subscribe(() => {
  const state = store.getState();
  if (typeof Listeners[state.lastAction] !== 'undefined') {
    Listeners[state.lastAction].call(null, state.cms[state.lastAction]);
  }
});

export {
  store,
  unsubscribe,
};
