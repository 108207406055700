import Cms from './Cms';
import { store } from './store/store';
import Actions from './store/Actions';
import Listeners from './store/Listeners';

class DataStore extends Cms{
  constructor() {
    super();
  }

  static dispatch(action, payload) {
    if (Actions.indexOf(action) < 0) {
      Actions.push(action);
    }
    store.dispatch({
      type: action,
      payload,
    });
  }

  static subscribe(action, callback) {
    Listeners[action] = callback;
  }

  static unsubscribe(action) {
    if (typeof Listeners[action] !== 'undefined') {
      delete Listeners[action];
    }
  }
}

export default DataStore;
